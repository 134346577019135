var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { api } from '~/tools/api/api';
import { plainToInstance } from 'class-transformer';
import { EApiUrl } from '~/tools/api/enums';
import { ModelCountry } from '~/models/model.country';
import { ModelUnityDocuments } from '~/models/model.unity.documents';
import { WrapperService } from '~/services/service.wrapper';
import { useService } from '~/services/service.base';
export let LinkAccountDialogService = class LinkAccountDialogService {
    open = false;
    showSignUp = false;
    showStep1 = true;
    showStep3 = false;
    showNotSuccess = false;
    showLinkAccount = false;
    showLoading = false;
    selectedCountry = '0';
    isPhoneInvalid = false;
    isEmailInvalid = false;
    isLinkUnityEmailInvalid = false;
    isDateOfBirthInvalid = false;
    isDateOfBirthLinkInvalid = false;
    isCreateAccountBtnDisabled = true;
    isLinkAccountBtnDisabled = true;
    isLinkUnityAuthError = false;
    isAgreeOneChecked = false;
    isAgreeTwoChecked = false;
    account = {};
    linkAccount = {};
    countries = observable.array([]);
    otpData;
    authService;
    unityDocuments;
    phoneNumber = null;
    constructor() {
        const wrapperService = useService(WrapperService);
        const { authService } = wrapperService;
        this.authService = authService;
        makeObservable(this);
    }
    handleOpen = () => (this.open = true);
    handleClose = () => {
        this.open = false;
        this.setShowStep1(true);
        this.setShowSignUp(false);
        this.setShowLinkAccount(false);
        this.setShowNotSuccess(false);
        this.setShowStep3(false);
        this.selectedCountry = '0';
    };
    handleOpenSignUp = async () => {
        await this.getCountries();
        await this.getDocuments();
        this.setShowStep1(false);
        this.setShowSignUp(true);
    };
    handleOpenLinkAccount = () => {
        this.setShowStep1(false);
        this.setShowLinkAccount(true);
    };
    setShowStep1 = (value) => {
        this.showStep1 = value;
    };
    setShowSignUp = (value) => {
        this.showSignUp = value;
    };
    setShowLinkAccount = (value) => {
        this.showLinkAccount = value;
    };
    handleCountryChange = (event) => {
        this.selectedCountry = event.target.value;
        if (this.selectedCountry === '0') {
            this.account.country = null;
        }
        else {
            this.account.country = this.selectedCountry;
        }
        this.validateAllFields();
    };
    handlePhoneNumberBlur = (event) => {
        const originPhone = event.target.value.trim().replace(/\s/g, "");
        if (originPhone) {
            this.phoneNumber = null;
            try {
                const phoneNumber = parsePhoneNumber(originPhone, originPhone.startsWith('+') ? undefined : 'US');
                const phoneType = phoneNumber?.getType();
                if (phoneNumber &&
                    phoneNumber.isValid() &&
                    phoneType &&
                    ['MOBILE', 'FIXED_LINE_OR_MOBILE'].includes(phoneType)) {
                    this.phoneNumber = phoneNumber;
                }
            }
            catch (error) {
                this.isPhoneInvalid = true;
            }
            if (!this.isPhoneInvalid && !this.phoneNumber) {
                this.isPhoneInvalid = true;
            }
        }
        this.validateAllFields();
    };
    handlePhoneNumberFocus = () => {
        this.isPhoneInvalid = false;
    };
    handleEmailBlur = (event) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
            this.isEmailInvalid = true;
            this.account.email = null;
        }
        else {
            this.isEmailInvalid = false;
            this.account.email = event.target.value;
        }
        this.validateAllFields();
    };
    handleEmailFocus = () => {
        this.isEmailInvalid = false;
    };
    handleFirstNameBlur = (event) => {
        if (event.target.value) {
            this.account.firstName = event.target.value;
        }
        else {
            this.account.firstName = null;
        }
        this.validateAllFields();
    };
    handleLastNameBlur = (event) => {
        if (event.target.value) {
            this.account.lastName = event.target.value;
        }
        else {
            this.account.lastName = null;
        }
        this.validateAllFields();
    };
    handleDateOfBirthBlur = (event) => {
        if (event.target.value) {
            const dateArr = event.target.value.split('/');
            if (dateArr.length < 3) {
                this.isDateOfBirthInvalid = true;
            }
            else {
                const month = dateArr[0];
                const day = dateArr[1];
                const year = dateArr[2];
                let isValid = true;
                if (month > 12) {
                    isValid = false;
                }
                if (isValid && day > 31) {
                    isValid = false;
                }
                const currentYear = new Date().getFullYear();
                const allowYear = currentYear - 18;
                if (isValid && (year > allowYear || year < 1000)) {
                    isValid = false;
                }
                this.isDateOfBirthInvalid = !isValid;
                this.account.dateBirth = isValid ? event.target.value : null;
            }
        }
        else {
            this.account.dateBirth = null;
        }
        this.validateAllFields();
    };
    handleDateOfBirthFocus = () => {
        this.isDateOfBirthInvalid = false;
    };
    validateAllFields = () => {
        if (this.account.firstName && this.account.lastName
            && this.account.dateBirth && this.account.country
            && this.account.email && !this.isPhoneInvalid
            && this.isAgreeOneChecked && this.isAgreeTwoChecked) {
            this.isCreateAccountBtnDisabled = false;
        }
        else {
            this.isCreateAccountBtnDisabled = true;
        }
    };
    getCountries = async () => {
        try {
            /*const response: AxiosResponse = await api.get<ModelCountry[]>(EApiUrl.countries);
            if (response.statusText?.toLowerCase() === 'ok') {
              const countries = plainToInstance(ModelCountry, response.data);
              this.setCountries(countries);
            }*/
            const data = [
                {
                    id: '1',
                    name: 'Canada'
                },
                {
                    id: '2',
                    name: 'USA'
                }
            ];
            const countries = plainToInstance(ModelCountry, data);
            this.setCountries(countries);
        }
        catch (error) { }
    };
    setCountries = (countries) => {
        // @ts-ignore
        this.countries.replace(countries);
    };
    verifyOtpUnityAccount = async (verificationCode) => {
        let verifyResult = false;
        this.otpData.otp = verificationCode;
        try {
            const response = await api.post(EApiUrl.unityOtp, this.otpData, {
                headers: {
                    Authorization: this.authService.currentUser?.token
                }
            }, true);
            if (response.status === 200 && !response.data.error) {
                verifyResult = true;
                this.authService.getUnityUser();
            }
        }
        catch (error) { }
        return verifyResult;
    };
    setShowLoading = (value) => {
        this.showLoading = value;
    };
    setShowNotSuccess = (value) => {
        this.showNotSuccess = value;
    };
    handleUnityEmailBlur = (event) => {
        this.setLinkUnityAuthError(false);
        if (event.target.value) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
                this.isLinkUnityEmailInvalid = true;
                this.linkAccount.unityAccountEmail = null;
            }
            else {
                this.isLinkUnityEmailInvalid = false;
                this.linkAccount.unityAccountEmail = event.target.value;
            }
        }
        else {
            this.linkAccount.unityAccountEmail = null;
        }
        this.validateLinkAccountAllFields();
    };
    validateLinkAccountAllFields = () => {
        this.isLinkAccountBtnDisabled = (!this.linkAccount.unityAccountEmail || !this.linkAccount.dateBirth);
    };
    handleDateOfBirthLinkBlur = (event) => {
        if (event.target.value) {
            const dateArr = event.target.value.split('/');
            if (dateArr.length < 3) {
                this.isDateOfBirthLinkInvalid = true;
            }
            else {
                const month = dateArr[0];
                const day = dateArr[1];
                const year = dateArr[2];
                let isValid = true;
                if (month > 12) {
                    isValid = false;
                }
                if (isValid && day > 31) {
                    isValid = false;
                }
                const currentYear = new Date().getFullYear();
                const allowYear = currentYear - 18;
                if (isValid && (year > allowYear || year < 1000)) {
                    isValid = false;
                }
                this.isDateOfBirthLinkInvalid = !isValid;
                this.linkAccount.dateBirth = isValid ? event.target.value : null;
            }
        }
        else {
            this.linkAccount.dateBirth = null;
        }
        this.validateLinkAccountAllFields();
    };
    handleAgreeOneChange = () => {
        this.isAgreeOneChecked = !this.isAgreeOneChecked;
        this.validateAllFields();
    };
    handleAgreeTwoChange = () => {
        this.isAgreeTwoChecked = !this.isAgreeTwoChecked;
        this.validateAllFields();
    };
    handleDateOfBirthLinkFocus = () => {
        this.isDateOfBirthLinkInvalid = false;
    };
    verifyOtpLinkUnity = async (verificationCode) => {
        let verifyResult = false;
        const data = {
            otp: verificationCode,
            transactionId: this.otpData.transactionId,
            unityUserId: this.otpData.unityUserId
        };
        try {
            const response = await api.post(EApiUrl.unityOtp, data, {
                headers: {
                    Authorization: this.authService.currentUser?.token
                }
            }, true);
            if (response.status === 200) {
                if (!response.data?.error) {
                    verifyResult = true;
                    this.authService.getUnityUser();
                }
            }
        }
        catch (error) { }
        return verifyResult;
    };
    setShowStep3 = (value) => {
        this.showStep3 = value;
    };
    handleOpenStep3 = async () => {
        if (!this.isLinkAccountBtnDisabled) {
            const sendResult = await this.sendVerificationCode();
            if (sendResult) {
                this.setShowLinkAccount(false);
                this.setShowStep3(true);
            }
        }
    };
    afterEnterCode = async (verificationCode) => {
        let verifyResult = false;
        this.setShowLoading(true);
        if (this.linkAccount.unityAccountEmail) {
            verifyResult = await this.verifyOtpLinkUnity(verificationCode);
        }
        else {
            verifyResult = await this.verifyOtpUnityAccount(verificationCode);
        }
        this.setShowLoading(false);
        if (verifyResult) {
            this.handleClose();
        }
        return verifyResult;
    };
    sendVerificationCode = async () => {
        let sendResult = false;
        this.setShowLoading(true);
        const email = this.linkAccount.unityAccountEmail ? this.linkAccount.unityAccountEmail : this.account.email;
        const dateArr = this.linkAccount.dateBirth ? this.linkAccount.dateBirth.split('/') : [];
        const month = dateArr[0];
        const day = dateArr[1];
        const year = dateArr[2];
        const dateOfBirth = `${year}-${month}-${day}`;
        const url = `${EApiUrl.unitySignIn}`;
        try {
            const response = await api.post(url, { email, dateOfBirth }, {
                headers: {
                    Authorization: this.authService.currentUser?.token
                }
            }, true);
            this.setShowLoading(false);
            if (response.status === 200) {
                sendResult = true;
                this.otpData = response.data;
            }
        }
        catch (error) {
            this.setShowLoading(false);
            this.setLinkUnityAuthError(true);
        }
        return sendResult;
    };
    setLinkUnityAuthError = (value) => {
        this.isLinkUnityAuthError = value;
    };
    getDocuments = async () => {
        try {
            const response = await api.get(EApiUrl.unityDocuments);
            if (response.statusText?.toLowerCase() === 'ok') {
                this.unityDocuments = plainToInstance(ModelUnityDocuments, response.data);
                for (const document of this.unityDocuments.documents) {
                    document.agreed = true;
                }
                ;
            }
        }
        catch (error) { }
    };
    unitySignUp = async () => {
        this.setShowLoading(true);
        try {
            const dateArr = this.account.dateBirth ? this.account.dateBirth.split('/') : [];
            const month = dateArr[0];
            const day = dateArr[1];
            const year = dateArr[2];
            const dateOfBirth = `${year}-${month}-${day}`;
            const data = {
                dateOfBirth,
                email: this.account.email,
                firstName: this.account.firstName,
                lastName: this.account.lastName,
                consents: this.unityDocuments.documents
            };
            if (this.phoneNumber) {
                // @ts-ignore
                data.mobile = this.phoneNumber.nationalNumber;
                // @ts-ignore
                data.mobileCountryCode = this.phoneNumber.countryCallingCode;
            }
            const response = await api.post(EApiUrl.unitySignUp, data, {
                headers: {
                    Authorization: this.authService.currentUser?.token
                }
            }, true);
            if (response.status === 200 && response.data.profileId) {
                await this.unitySignIn(dateOfBirth, this.account?.email);
            }
            else {
                this.setShowSignUp(false);
                this.setShowNotSuccess(true);
            }
        }
        catch (error) {
            this.setShowSignUp(false);
            this.setShowNotSuccess(true);
        }
        this.setShowLoading(false);
    };
    unitySignIn = async (dateOfBirth, email) => {
        try {
            const response = await api.post(EApiUrl.unitySignIn, { dateOfBirth, email }, {
                headers: {
                    Authorization: this.authService.currentUser?.token
                }
            }, true);
            if (response.status === 200 && response.data.unityUserId) {
                this.otpData = response.data;
                this.setShowSignUp(false);
                this.setShowStep3(true);
            }
            else {
                this.setShowSignUp(false);
                this.setShowNotSuccess(true);
            }
        }
        catch (error) {
            this.setShowSignUp(false);
            this.setShowNotSuccess(true);
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showSignUp", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showStep1", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showStep3", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showNotSuccess", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showLinkAccount", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], LinkAccountDialogService.prototype, "selectedCountry", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isPhoneInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isEmailInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isLinkUnityEmailInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isDateOfBirthInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isDateOfBirthLinkInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isCreateAccountBtnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isLinkAccountBtnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isLinkUnityAuthError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isAgreeOneChecked", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isAgreeTwoChecked", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpenSignUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpenLinkAccount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setShowStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setShowSignUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setShowLinkAccount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleCountryChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handlePhoneNumberBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handlePhoneNumberFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleEmailBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleEmailFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleFirstNameBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleLastNameBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleDateOfBirthBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleDateOfBirthFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "validateAllFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "getCountries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setCountries", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "verifyOtpUnityAccount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setShowNotSuccess", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleUnityEmailBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "validateLinkAccountAllFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleDateOfBirthLinkBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleAgreeOneChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleAgreeTwoChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleDateOfBirthLinkFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "verifyOtpLinkUnity", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setShowStep3", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpenStep3", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "afterEnterCode", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "setLinkUnityAuthError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "getDocuments", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "unitySignUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "unitySignIn", void 0);
LinkAccountDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], LinkAccountDialogService);
