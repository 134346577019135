var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
import { plainToInstance } from 'class-transformer';
import { api } from '~/tools/api/api';
import { EApiUrl } from '~/tools/api/enums';
import Cookies from 'js-cookie';
import { ModelUnityUser } from '~/models/model.unity.user';
import { ModelUser } from '~/models/model.user';
export class AuthService {
    currentUser;
    showUnityBanner = true;
    constructor() {
        makeObservable(this);
    }
    initUser = async () => {
        const userToken = Cookies.get('userToken');
        if (userToken) {
            await this.getUser(userToken);
            await this.getUnityUser();
        }
    };
    getUnityUser = async () => {
        if (this.currentUser?.token) {
            try {
                const response = await api.get(EApiUrl.unityUserSummary, {
                    headers: {
                        Authorization: this.currentUser?.token
                    }
                }, true);
                if (response.status === 200 && response.data.summary && response.data.summary.accountNumber) {
                    this.setUnityData(plainToInstance(ModelUnityUser, response.data));
                }
            }
            catch (error) { }
        }
    };
    getUser = async (token) => {
        try {
            const response = await api.get(EApiUrl.profile, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                response.data.token = token;
                response.data.isUnityLinked = false;
                this.setCurrentUser(plainToInstance(ModelUser, response.data));
            }
        }
        catch (error) { }
    };
    login = async (token) => {
        try {
            const response = await api.get(EApiUrl.profile, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                response.data.token = token;
                const date = new Date();
                date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                this.setUserSession({ name: 'userToken', token }, { path: '/', expires: date });
                this.setCurrentUser(plainToInstance(ModelUser, response.data));
                this.getUnityUser();
            }
        }
        catch (error) { }
    };
    logout = () => {
        Cookies.remove('userToken');
        this.setCurrentUser(null);
        this.showUnityBanner = true;
    };
    setCurrentUser = (user) => {
        this.currentUser = user;
    };
    setUnityData = (unityUser) => {
        if (this.currentUser && unityUser?.summary?.accountNumber) {
            this.showUnityBanner = false;
            this.currentUser.totalUnityPoints = unityUser?.summary?.totalUnityPoints;
        }
    };
    setUserSession = (userSession, options) => {
        Cookies.set(userSession.name, userSession.token, options);
    };
}
__decorate([
    observable,
    __metadata("design:type", Object)
], AuthService.prototype, "currentUser", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AuthService.prototype, "showUnityBanner", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "initUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "getUnityUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "getUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "login", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "logout", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setCurrentUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setUnityData", void 0);
