var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable } from './service.base';
import { action, makeObservable, observable } from 'mobx';
export let PaysafeFormService = class PaysafeFormService {
    cardNumberValid = false;
    cardNumberError = false;
    expiryDateError = false;
    cvcError = false;
    btnDisabled = true;
    cardSaveOptionChecked = true;
    addNewShowLoading = false;
    addSelectedCardShowLoading = false;
    confirmAndPayDisabled = true;
    isCvcInvalid = false;
    paysafeError = '';
    paysafeErrorSelectedCard = '';
    cardHolder = '';
    creditCards = observable.array([]);
    selectedCreditCard;
    paysafejsApiKey = process.env.PAYSAFEJS_API_KEY ? process.env.PAYSAFEJS_API_KEY : '';
    paysafeEnv = process.env.PAYSAFEJS_ENV ? process.env.PAYSAFEJS_ENV : 'TEST';
    paysafeDefaultAccount = process.env.PAYSAFEJS_ACCOUNT ? process.env.PAYSAFEJS_ACCOUNT : 0;
    paysafeInstance = null;
    selectedCardPaysafeInstance = null;
    paysafeInitialized = false;
    paysafeSelectedCardInitialized = false;
    constructor() {
        makeObservable(this);
    }
    handleCardSaveOptionChange = () => {
        this.cardSaveOptionChecked = !this.cardSaveOptionChecked;
    };
    onCardHolderBlur = (event) => {
        if (event.target.value) {
            this.cardHolder = event.target.value;
        }
    };
    payByNewCreditCard = async (wdollarPackage, currentUser) => {
        this.setPaysafeError('');
        if (!this.btnDisabled) {
            this.setAddNewShowLoading(true);
            let paymentResult = null;
            const tokenizationOptions = {
                amount: wdollarPackage.price,
                merchantRefNum: this.generateGuid(),
                transactionType: "PAYMENT",
                paymentType: "CARD",
                customerDetails: {
                    /*billingDetails: {
                      country: "US",
                      zip: "90210",
                      street: "Oak Fields 6",
                      city: "ca",
                      state: "CA"
                    },*/
                    profile: {
                        firstName: currentUser?.firstName,
                        lastName: currentUser?.lastName,
                        email: currentUser?.email
                    }
                }
            };
            try {
                // @ts-ignore
                /*paymentResult = await this.paysafeInstance.tokenize(tokenizationOptions);
                paymentResult.cardHolder = this.cardHolder;
                paymentResult.isSaveCard = this.cardSaveOptionChecked;*/
                paymentResult = Promise.resolve({ token: 'SCJu5YmbO8rWWTyc', cardHolder: this.cardHolder, isSaveCard: this.cardSaveOptionChecked });
            }
            catch (error) {
                this.setAddNewShowLoading(false);
                if (error.detailedMessage) {
                    this.setPaysafeError(error.detailedMessage);
                }
            }
            return paymentResult;
        }
    };
    generateGuid = () => {
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    };
    initPaysafe = async () => {
        if (!this.paysafeInitialized) {
            this.paysafeInitialized = true;
            const paysafeOptions = {
                currencyCode: 'USD',
                accounts: {
                    default: +this.paysafeDefaultAccount
                },
                environment: this.paysafeEnv,
                fields: {
                    cardNumber: {
                        selector: '#cardNumber',
                        separator: ' '
                    },
                    cvv: {
                        selector: '#cardCVC',
                        mask: false,
                        optional: false
                    },
                    expiryDate: {
                        selector: '#cardExpiry',
                        placeholder: 'MM / YY'
                    }
                },
                style: {
                    input: {
                        "color": '#777E90',
                        "font-size": '14px',
                        "line-height": '24px'
                    }
                }
            };
            try {
                // @ts-ignore
                this.paysafeInstance = await paysafe.fields.setup(this.paysafejsApiKey, paysafeOptions);
                const paymentMethods = await this.paysafeInstance.show();
                this.addPaysafeEventListeners();
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    initSelectedCardPaysafe = async () => {
        if (!this.paysafeSelectedCardInitialized) {
            this.paysafeSelectedCardInitialized = true;
            const paysafeOptions = {
                currencyCode: 'USD',
                accounts: {
                    default: +this.paysafeDefaultAccount
                },
                environment: this.paysafeEnv,
                fields: {
                    cardNumber: {
                        selector: '#selectedCardNumber',
                        optional: true
                    },
                    cvv: {
                        selector: '#selectedCardCvc'
                    },
                    expiryDate: {
                        selector: '#selectedCardExpiry',
                        optional: true
                    }
                },
                style: {
                    input: {
                        "color": '#777E90',
                        "font-size": '14px',
                        "line-height": '24px'
                    }
                }
            };
            try {
                // @ts-ignore
                this.selectedCardPaysafeInstance = await paysafe.fields.setup(this.paysafejsApiKey, paysafeOptions);
                this.addSelectedPaysafeEventListeners();
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    setBtnDisabled = (value) => {
        this.btnDisabled = value;
    };
    setCardNumberValid = (value) => {
        this.cardNumberValid = value;
    };
    setCardNumberError = (value) => {
        this.cardNumberError = value;
    };
    setExpiryDateError = (value) => {
        this.expiryDateError = value;
    };
    setCvcError = (value) => {
        this.cvcError = value;
    };
    addPaysafeEventListeners = () => {
        this.paysafeInstance.fields('cvv cardNumber expiryDate').valid((eventInstance, event) => {
            if (eventInstance.areAllFieldsValid()) {
                this.setBtnDisabled(false);
            }
            switch (event.target.fieldName) {
                case 'CardNumber':
                    this.setCardNumberError(false);
                    this.setCardNumberValid(true);
                    break;
                case 'ExpiryDate':
                    this.setExpiryDateError(false);
                    break;
                case 'Cvv':
                    this.setCvcError(false);
                    break;
                default:
                    break;
            }
        });
        this.paysafeInstance.fields('cvv cardNumber expiryDate').invalid((eventInstance, event) => {
            this.setBtnDisabled(true);
            switch (event.target.fieldName) {
                case 'CardNumber':
                    this.setCardNumberValid(false);
                    this.setCardNumberError(true);
                    break;
                case 'ExpiryDate':
                    this.setExpiryDateError(true);
                    break;
                case 'Cvv':
                    this.setCvcError(true);
                    break;
                default:
                    break;
            }
        });
    };
    addSelectedPaysafeEventListeners = () => {
        this.selectedCardPaysafeInstance.fields('cvv').valid((eventInstance, event) => {
            this.setConfirmAndPayDisabled(false);
        });
        this.selectedCardPaysafeInstance.fields('cvv').invalid((eventInstance, event) => {
            this.setConfirmAndPayDisabled(true);
        });
    };
    setConfirmAndPayDisabled = (value) => {
        this.confirmAndPayDisabled = value;
    };
    getUserCreditCards = async (currentUser) => {
        if (!this.creditCards.length) {
            try {
                /*const response: AxiosResponse = await api.get(EApiUrl.creditCard, {
                  headers: {
                    Authorization: currentUser ? `Bearer ${currentUser.token}` : ``
                  }
                });
                if (response.statusText?.toLowerCase() === 'ok') {
                  this.setCreditCards(response.data);
                  if (response.data && response.data.length === 1) {
                    this.selectedCreditCard = response.data[0];
                  }
                  setTimeout(() => {this.initSelectedCardPaysafe();}, 2000);
                }*/
                const data = [
                    {
                        id: '1',
                        cardNumber: '**** **** **** 1425',
                        cardExpiration: '12/2025',
                        cardType: 'Visa',
                        singleUseCustomerToken: 'PrwxyviVAe4Zau3',
                        paymentTokenFrom: 'PrwxyviVAe4Zau3'
                    }
                ];
                this.setCreditCards(data);
                this.selectedCreditCard = data[0];
                setTimeout(() => { this.initSelectedCardPaysafe(); }, 2000);
            }
            catch (error) { }
        }
    };
    setCreditCards = (creditCards) => {
        // @ts-ignore
        this.creditCards.replace(creditCards);
    };
    setCvcInvalid = (value) => {
        this.isCvcInvalid = value;
    };
    payBySelectedCreditCard = async (wdollarPackage) => {
        this.setPaysafeErrorSelectedCard('');
        if (!this.confirmAndPayDisabled) {
            this.setAddSelectedCardShowLoading(true);
            let paymentResult = null;
            const tokenizationOptions = {
                amount: wdollarPackage.price,
                merchantRefNum: this.generateGuid(),
                transactionType: "PAYMENT",
                paymentType: "CARD",
                singleUseCustomerToken: this.selectedCreditCard.singleUseCustomerToken,
                paymentTokenFrom: this.selectedCreditCard.paymentTokenFrom
            };
            try {
                this.paysafeInstance.resetCardDetails();
                // @ts-ignore
                paymentResult = await this.selectedCardPaysafeInstance.tokenize(tokenizationOptions);
                //paymentResult = Promise.resolve({token: 'SCJu5YmbO8rWWTyc'});          
            }
            catch (error) {
                this.setAddSelectedCardShowLoading(false);
                if (error.detailedMessage) {
                    this.setPaysafeErrorSelectedCard(error.detailedMessage);
                }
            }
            return paymentResult;
        }
    };
    setPaysafeError = (value) => {
        this.paysafeError = value;
    };
    setPaysafeErrorSelectedCard = (value) => {
        this.paysafeErrorSelectedCard = value;
    };
    setAddSelectedCardShowLoading = (value) => {
        this.addSelectedCardShowLoading = value;
    };
    setAddNewShowLoading = (value) => {
        this.addNewShowLoading = value;
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cardNumberValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cardNumberError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "expiryDateError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cvcError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "btnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cardSaveOptionChecked", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "addNewShowLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "addSelectedCardShowLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "confirmAndPayDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "isCvcInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "paysafeError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "paysafeErrorSelectedCard", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "handleCardSaveOptionChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "onCardHolderBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "payByNewCreditCard", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setBtnDisabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCardNumberValid", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCardNumberError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setExpiryDateError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCvcError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "addPaysafeEventListeners", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setConfirmAndPayDisabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCreditCards", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCvcInvalid", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setPaysafeError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setPaysafeErrorSelectedCard", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setAddSelectedCardShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setAddNewShowLoading", void 0);
PaysafeFormService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], PaysafeFormService);
