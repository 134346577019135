import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { SwiperWdollarsNavigations } from '~/views/molecules/swiper.wdollars.navigations';
import { useService } from '~/services/service.base';
import { ROUTES } from '~/routes/routes';
import { WrapperService } from '~/services/service.wrapper';
import { DialogService } from '~/services/service.dialog';
import 'swiper/css';
export const BuyNow = observer((props) => {
    const navigate = useNavigate();
    const wrapperService = useService(WrapperService);
    const { paymentService, authService } = wrapperService;
    const dialogService = useService(DialogService);
    const { signInDialogSrv } = dialogService;
    const swiper = useSwiper();
    useEffect(() => {
        paymentService.getWdollarPackages();
    }, []);
    const onButtonClickHandler = (item, e) => {
        if (authService.currentUser) {
            navigate(ROUTES.PAYMENT.path.replace(':id', item.id), { state: { id: item.id } });
        }
        else {
            signInDialogSrv.handleOpen(e);
        }
    };
    return (_jsx("section", { className: "buy-now", id: "buy-now", children: _jsxs("div", { className: "container", children: [_jsx("div", { className: "buy-now-wrap", children: paymentService.wdollarPackages.map((item, index) => (index < 3 ?
                        (_jsx("div", { className: "buy-now-item", children: _jsxs("div", { className: "buy-now-item-inner", children: [_jsxs("div", { className: "buy-now-img-wrap", children: [_jsx("div", { className: "buy-now-discount", children: item.discountText }), _jsx("figure", { children: _jsx("img", { src: `/assets/img/${item.package}-dollars.svg` }) })] }), _jsxs("div", { className: "buy-now-text-wrap", children: [_jsxs("h4", { children: ["Pay $", item.price, " ", '>', " Get $", item.package] }), _jsx("p", { children: "Get an amazing discount and free play at  casino when you purchase W Dollars!" })] }), _jsx("div", { className: "buy-now-btn-wrap", children: _jsxs("button", { type: "button", className: "btn", onClick: (e) => onButtonClickHandler(item, e), children: ["$", item.price] }) })] }) }, item.id))
                        : null)) }), paymentService.showAllMode === false && (_jsx("div", { id: "buy-now-view-all-content", className: "buy-now-wrap", children: paymentService.wdollarPackages.map((item, index) => (index >= 3 ?
                        (_jsx("div", { className: "buy-now-item", children: _jsxs("div", { className: "buy-now-item-inner", children: [_jsxs("div", { className: "buy-now-img-wrap", children: [_jsx("div", { className: "buy-now-discount", children: item.discountText }), _jsx("figure", { children: _jsx("img", { src: `/assets/img/${item.package}-dollars.svg` }) })] }), _jsxs("div", { className: "buy-now-text-wrap", children: [_jsxs("h4", { children: ["Pay $", item.price, " ", '>', " Get $", item.package] }), _jsx("p", { children: "Get an amazing discount and free play at  casino when you purchase W Dollars!" })] }), _jsx("div", { className: "buy-now-btn-wrap", children: _jsxs("button", { type: "button", className: "btn", onClick: (e) => onButtonClickHandler(item, e), children: ["$", item.price] }) })] }) }, item.id))
                        : null)) })), _jsx("div", { className: "buy-now-view-wrap", children: _jsxs("button", { id: "buy-now-view", type: "button", className: "btn", onClick: paymentService.handleViewAll, children: [_jsx("span", { id: "buy-now-view-all", className: paymentService.showAllMode ? "" : "hide", children: "View all" }), _jsx("span", { id: "buy-now-view-less", className: !paymentService.showAllMode ? "" : "hide", children: "View Less" })] }) }), _jsx("div", { className: "buy-now-wrap-mobile", children: _jsxs(Swiper, { className: "redeem-amounts-items redeem-slider", spaceBetween: 10, slidesPerView: 1, onSlideChange: paymentService.handleSlideChange, breakpoints: {
                            1000: { slidesPerView: 3 },
                            625: { slidesPerView: 2 },
                            450: { slidesPerView: 1 },
                            320: { slidesPerView: 1.2 }
                        }, children: [_jsx(SwiperWdollarsNavigations, {}), paymentService.wdollarPackages.map((item, index) => (_jsx(SwiperSlide, { className: "wdollar-package-item", children: _jsx("div", { className: "buy-now-item", children: _jsxs("div", { className: "buy-now-item-inner", children: [_jsxs("div", { className: "buy-now-img-wrap", children: [_jsx("div", { className: "buy-now-discount", children: item.discountText }), _jsx("figure", { children: _jsx("img", { src: `/assets/img/${item.package}-dollars.svg` }) })] }), _jsxs("div", { className: "buy-now-text-wrap", children: [_jsxs("h4", { children: ["Pay $", item.price, " ", '>', " Get $", item.package] }), _jsx("p", { children: "Get an amazing discount and free play at  casino when you purchase W Dollars!" })] }), _jsx("div", { className: "buy-now-btn-wrap", children: _jsxs("button", { type: "button", className: "btn", onClick: (e) => onButtonClickHandler(item, e), children: ["$", item.price] }) })] }) }) }, index)))] }) })] }) }));
});
